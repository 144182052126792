.App {
  text-align: center;
  max-height: 100vh;
  height: 100vh;
}

.crowdview-participant-container {
  background-color: black;
  max-height: 100%;
  max-width: 100%;
}

.forced-aspect-ratio {
  aspect-ratio: 16/9;
}

.auto-aspect-ratio {
  height: 100%;
  width: 100%;
}

.crowdview-grid {
  display: grid;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
}

.crowdview-participant-video {
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  width: 100%;
  position: fixed;
}

.crowdview-participant-video > video {
  object-fit: cover;
  position: fixed;
}

.crowdview-placeholder-img > img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
